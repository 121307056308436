<template>
  <v-container
    id="fido2Keys"
    fluid
    tag="section"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
    </div>
    <base-material-card
      color="indigo"
      icon="mdi-fingerprint"
      inline
      class="px-5 py-3"
      hover-reveal
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light mt-2">
          FIDO2 Key List of {{ username }}
        </div>
      </template>

      <v-row class="align-center">
        <v-btn
          color="primary"
          class="mx-3"
          text
          to="/configs/users"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          Back
        </v-btn>
        <v-spacer />

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          label="Search"
          hide-details
          style="max-width: 250px; margin-bottom: 5px; margin-right: 12px"
        />

        <v-btn
          color="primary"
          class="mx-3"
          @click="regisDialog = true"
        >
          <v-icon left>
            mdi-key-plus
          </v-icon>
          New Key
        </v-btn>
      </v-row>

      <v-divider class="mt-3" />

      <v-data-table
        :headers="computedHeaders"
        :items="fido2Keys"
        :search.sync="search"
        :sort-by="['registerDate']"
        multi-sort
        sort-desc
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                color="blue"
                class="ma-2"
                outlined
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="editFido2Key(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                class="ma-2"
                outlined
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="deleteFido2Key(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getColor(item.status)"
            dark
          >
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
      </v-data-table>

      <!-- Edit Form -->
      <template>
        <v-dialog
          v-model="editDialog"
          max-width="400px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Edit FIDO2 Key</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-text-field
                  ref="username"
                  v-model="username"
                  label="Username"
                  prepend-icon="mdi-account"
                  readonly
                  filled
                />

                <v-text-field
                  ref="keyName"
                  v-model="editedFido2Key.keyName"
                  label="Key Name"
                  prepend-icon="mdi-shield-key"
                  :rules="[rules.keyName.notEmpty, rules.keyName.length]"
                />

                <v-select
                  v-model="editedFido2Key.status"
                  prepend-icon="mdi-checkbox-marked-outline"
                  :items="keys_status"
                  label="Choose Key Status"
                />
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                min-width="100"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                min-width="100"
                @click="validateForm"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!-- Register Form -->
      <template>
        <v-dialog
          v-model="regisDialog"
          max-width="400px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Register FIDO2 Key</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="username"
                  label="Username"
                  prepend-icon="mdi-account"
                  readonly
                  filled
                />

                <v-text-field
                  ref="keyName"
                  v-model="formAddKey.keyName"
                  label="Enter Key Name"
                  prepend-icon="mdi-shield-key"
                  :rules="[rules.keyName.notEmpty, rules.keyName.length]"
                  :counter="30"
                  clearable
                />

                <v-radio-group
                  v-model="regType"
                  row
                  class="ml-4 mt-0"
                  :mandatory="false"
                >
                  <v-radio
                    label="FIDO2 Key register"
                    value="FIDO2"
                  />
                  <v-radio
                    label="QR register"
                    value="QRCode"
                  />
                </v-radio-group>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                min-width="100"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                min-width="100"
                @click="validateForm"
              >
                Register
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="showQR"
          persistent
          max-width="350px"
        >
          <v-card>
            <v-card-text>
              <div class="text-h3 subtitle-1 font-weight-light text-center">
                Scan the QR code
              </div>

              <v-img :src="imgqr" />

              <v-row class="text-center">
                <v-col
                  md="4"
                  sm="4"
                  lg="4"
                />
                <v-col
                  md="4"
                  sm="4"
                  lg="4"
                >
                  <v-btn
                    width="100%"
                    color="success"
                    @click="handleQRCancel"
                  >
                    Cancel {{ countDown }}
                  </v-btn>
                </v-col>
                <v-col
                  md="4"
                  sm="4"
                  lg="4"
                />
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </base-material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import {
    preformatMakeCredReq,
    publicKeyCredentialToJSON,
  } from '../../../utils/helpers'
  import LocalStorageService, { getAccessToken } from '../../../utils/auth'

  const localStorageService = LocalStorageService.getService()
  let msgServer

  export default {
    name: 'DashboardExtendedTables',
    data: () => ({
      loading: false,
      username: '',
      requestError: false,
      requestErrorMessage: '',
      urls: {
        fido2Attestation: '/v1/users/add/attestation/result',
        QRsse: '/v1/register/events',
        put: '/v1/api/keys/update',

        child: {
          load: '/v1/api/corp/users/keys', // :keyID
          post: '/v1/api/users/keys/add',
          delete: '/v1/api/corp/keys/delete', // /:username/:id
        },
        master: {
          load: '/v1/api/keys',
          post: '/v1/api/keys',
          delete: '/v1/api/keys/delete',
        },
      },

      // table
      fido2Keys: [],
      headers: [
        { text: 'ID', value: 'keyID', sortable: false, filterable: false },
        { text: 'Key Name', value: 'keyName' },
        { text: 'Register At', value: 'registerDate' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      keys_status: [
        { text: 'InActive', value: false },
        { text: 'Active', value: true },
      ],
      statuses: ['Active', 'InActive'],
      search: undefined,

      // Add key
      regisDialog: false,
      regType: 'FIDO2',
      imgqr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQMAAACXljzdAAAABlBMVEX///8AAABVwtN+AAAD10lEQVR4nOzYPY4sJxcG4JevPokM7MxByeVlENhiS1Whk67qDUxv6QRXYhtl1QYgI0C8Fv3jO3fslu4wE16ibj39Q8PhnEPjx/j0ocnAwAsZsNI4bl6RsVegAtyUxAZuWpFVK8HcK1MSCman5LgwYnjxKpQPSIAfZw8/2hzdqMIHpbp9zdBMwXiePyRQoWI3LgUsQYVhc69W591CBuOOF5fCuNwffd2590obicfmqsfJA+OsX8XPu2U4i81DRdWkJOEG+CP2ylF9dSO08cc5MBR44Lqinyu6WFa3w6sWo9WPpzaX2CkY4Y0vxkUPo40vKhg99IrGmqMfLjSaifC7Fct97hSwwnJfg9EFsFJsNh7dMiVWvZ88hZszfl/E8tg6RZdZpzwuAXq60EpZA9xtDXpkXIU8LjR+Stn40dLgA2JgNIymDC8arsywYdh6hecQcbz46MoabICVqse5V8aTgx/hkwwbUsbJqYxeAVZaYmFLjNej0gIInaIZffUwrrr95JKMgLnvz0Ou7//5uwTHWWwYWO7F7Zq072f7U2WcXQRjm8EqE6czLW8Z6asMFU/E5m9EwwrD1DZ+ilCE8ffz80r8/kxW+UbA9hlTKgAFP+nhnB/57ZVs/y2/vRVNspUE+UVxa685C3mP0buYp6L/JWXWFeP6ZTgTMDheEPW3ov56Jngr2JeQMmzB0mbtRoMU3sifT+WPN6IZdXRD/D+ma4EZgagLOgXjSuCI7iq/ArMmb/vzkP89FcU30uqGCkPKaGLluvmPWd9lfSrTW8HClMv8O2zbn3wkVnePqodo4omot1JmRLSuBidgEqzB/JN7H4LvlzWrMC5fHmdO5UeP1CGYXnwK+3L7HjgmMW7/sFjuxr8WXVSI7qAYHGdW1yrg4/d0CFvTSkrVbUmxr1TsFpS2uBOZ8m5JljUw32K0R1h11EeTqerqmaTec2+PXEOAFUqGFFKYzpLC7Sz0yHhq5eoSEodL642XwFYc+kRP56w4bS76smbjypJtvvfxPbLBtNuIZeuNw0R51PoeGZdscFwE7oiO5Kat3GpJh+CoUBxP7fmFSVo3KPvcKXq0Icm1CkyU6w1FhX5hdIm7ATm0grkvweCR394tgBKVyyotqEVlUnjvez9ZoGtrFlvelKqLYrz38R3Sbj1GH+3KOK5MYTc6hVuM9giUWBk2D42TgyurRH3rr3tkShL9cP1DICJqGEd+RIKSAk8ZVbvH28BwW4M+Eeh9ydFh9krKKtXvc69ASeLAbDAuUtEO+L2H7ZG2Px7QNuwnRznOQnl0T++WH6Nn/B0AAP//7hw3VCp2XD0AAAAASUVORK5CYII=',
      countDown: 40,
      showQR: false,
      formAddKey: {
        keyID: -1,
        username: '',
        keyName: '',
        registerDate: '',
        status: false,
      },

      // Edit key
      editDialog: false,
      editedIndex: -1,
      editedId: -1,
      editedFido2Key: {
        keyID: -1,
        username: '',
        keyName: '',
        registerDate: '',
        status: false,
      },
      defaultFido2Key: {
        username: '',
        keyName: '',
        registerDate: '',
        status: false,
      },
      rules: {
        keyName: {
          notEmpty: v => !!v || 'Required',
          length: v => v.length <= 30 || 'Max length is 30',
        },
      },

    }),

    computed: {
      computedHeaders () {
        return this.headers.filter(header => header.text !== 'ID')
      },
    },

    watch: {
      regisDialog (newState) {
        // console.log('regisDialog')
        /*        if (newState) {
          this.formAddKey = {}
        } */
        this.formAddKey.keyName = (this.username + '_' + this.rndStr(9)).toUpperCase()
      },

      regType: function (n, o) {
        // console.log('regisType', n, o)
        if (n === 'QRCode') {
          this.formAddKey.keyName = (this.username + '_' + 'QR' + '_' + this.rndStr(6)).toUpperCase()
        } else {
          this.formAddKey.keyName = (this.username + '_' + this.rndStr(9)).toUpperCase()
        }
      },

      countDown: function (n, o) {
        // console.log('countDown')
        if (n <= 0) {
          this.showQR = false
          this.countDown = 0
          this.regisDialog = false
        }
      },
    },

    created () {
      if (this.$store.getters.getChild) {
        this.username = this.$store.getters.getChild.split('@')[0]
      }
      this.loadItems()
    },

    beforeDestroy () {
      // console.log('beforeDestroy')
      // Make sure to close the connection with the events server
      // when the component is destroyed, or we'll have ghost connections!
      if (msgServer !== undefined) { msgServer.close() }
    },

    methods: {
      /* _____________________Table Data_______________________ */
      /* ______________________________________________________ */
      async loadItems () {
        // console.log('loadItems')
        this.loading = true
        var url = `${this.urls.child.load}/${this.username}`
        await axios.get(url)
          .then((response) => {
            // console.log(response)
            this.fido2Keys = response.data.data
            // console.log('keys', this.fido2Keys)
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      /* __________________________Dialog______________________ */
      /* ______________________________________________________ */
      validateForm () {
        // console.log('validateForm', this.$refs.keyName.validate())
        if (!this.$refs.keyName.validate()) {
          this.$refs.keyName.validate(true)
          return
        }
        if (this.regisDialog) {
          this.keyRegister(this.formAddKey)
          this.regisDialog = false
        } else if (this.editDialog) {
          this.edit_save(this.editedFido2Key)
          this.editDialog = false
        }
      },

      /* ________________________Add New_______________________ */
      /* ______________________________________________________ */
      async keyRegister (item) {
        // console.log('keyRegister')
        const data = {
          username: this.username,
          keyName: item.keyName,
          regType: this.regType,
        }
        var url = this.urls.child.post
        await axios.post(url, data).then(response => {
          // console.log(response)
          if (this.regType === 'FIDO2') {
            this.handleFido2RegisterResponse(response)
          } else {
            this.handleQRRegisterResponse(response)
          }
        }).catch(e => {
          this.handleErrors(e)
        }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      handleFido2RegisterResponse (response) {
        // console.log(' type FIDO2', response)
        const makeCredChallenge = preformatMakeCredReq(response.data)
        // console.log('test')
        navigator.credentials.create({ publicKey: makeCredChallenge })
          .then((response) => {
            // console.log('test1', response)
            const newCredentialInfo = publicKeyCredentialToJSON(response)
            // console.log('newCredent...')
            return axios.post(
              this.urls.fido2Attestation,
              newCredentialInfo,
              {
                headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
                withCredentials: false,
              },
            )
          }).then(response => {
            // console.log('response', response)
            // const responseData = JSON.(response.data)
            // console.log('response.data', response.data)
            if (response.data.success) {
              this.show_message('success', 'Register key success!')
              // console.log('new key', response.data.data)
              var newKey = response.data.data
              newKey.registerDate = new Date().toISOString().substr(0, 19) + 'Z'
              // console.log('newKey created at', newKey.registerDate, newKey)
              this.fido2Keys.push(newKey)
            // this.add_cancel()
            }
          }).catch(e => {
            // console.log('123456', e)
            this.handleErrors(e)
          }).finally(() => (this.loading = false))
      },

      handleQRRegisterResponse (response) {
        // console.log(' type QR')
        this.imgqr = 'data:image/png;base64,' + response.data.data.img
        this.regToken = response.data.data.token
        this.showQR = true
        this.countDown = 40
        this.countDownTimer()
        localStorageService.setChannel(this.regToken)
        this.$sse(`${this.urls.QRsse}/${this.regToken}`, { format: 'json' })
          .then(sse => {
            // console.log(sse.getSource())
            msgServer = sse
            sse.onError(e => {
              // console.error('Connection lost', e)
            })

            sse.getSource().onmessage = function (event) {
              // console.log('Message on channel-1: ' + event.data)
            }

            // console.log(sse)

            // Listen for messages without a specified event
            sse.subscribe('', (data, rawEvent) => {
              // console.warn('Received a message w/o an event!', data)
            })

            // Listen for messages based on their event (in this case, "chat")
            sse.subscribe('register', (message, rawEvent) => {
              // console.log(message)
              this.show_message('success', message.keyName + ' register success!')
              this.countDown = 0
              sse.unsubscribe('register')
              localStorageService.clearChannel()
              var newKey = message
              newKey.registerDate = new Date().toISOString().substr(0, 19) + 'Z'
              // console.log('newKey created at', newKey.registerDate, newKey)
              this.fido2Keys.push(newKey)
              sse.close()
            })

            // Unsubscribes from event-less messages after 7 seconds
            setTimeout(() => {
              sse.unsubscribe('')

              // console.log('Stopped listening to event-less messages!')
            }, 7000)

            // Unsubscribes from chat messages after 7 seconds
            setTimeout(() => {
              sse.unsubscribe('register')

              // console.log('Stopped listening to register messages!')
              sse.close()
              localStorageService.clearChannel()
            }, 40000)
          }).catch(e => {
            this.handleErrors('QR service not available!')
          })
        // storing jwt in localStorage. https cookie is safer place to store
        // localStorage.setItem('token', token)
        // localStorage.setItem('user', user)
        // axios.defaults.headers.common.Authorization = 'Bearer ' + token
        // mutation to change state properties to the values passed along
        // commit('auth_success', { token, user })
        // resolve(response)
      },

      async handleQRCancel () {
        // console.log('handleQRCancel')
        this.countDown = 0
        const channel = localStorageService.getChannel()
        if (channel) {
          await axios.get(`${this.urls.QRsse}/${channel}/canceladd`)
            .then(sse => {
              // console.log(sse)
              msgServer.close()
              localStorageService.clearChannel()
            }).catch(() => {
              this.show_message('error', 'No channel to cancel')
            })
        }
      },

      /* __________________________Edit________________________ */
      /* ______________________________________________________ */
      editFido2Key (item) {
        // console.log('editFido2Key')
        this.editedIndex = this.fido2Keys.indexOf(item)
        this.editedId = item.id
        this.editedFido2Key = Object.assign({}, item)
        this.editDialog = true
      },

      async edit_save (item) {
        // console.log('edit_save')
        const data = item
        data.username = this.username
        const url = `${this.urls.put}/${item.keyID}`
        this.loading = true // the loading begin
        await axios.put(url, data)
          .then((response) => {
            // console.log('editedIndex', this.editedIndex)
            // console.log('editSave data', response.data.data)
            Object.assign(this.fido2Keys[this.editedIndex], response.data)
            this.editDialog = false
            this.editedApp = {}
            this.$swal.fire({
              titleText: `Key ${response.data.keyName} has been updated`,
              icon: 'success',
              showCancelButton: false,
            })
          }).catch(e => {
            // console.log('editSave error', e)
            this.handleErrors(e)
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      /* _________________________Delete_______________________ */
      /* ______________________________________________________ */
      async deleteFido2Key (item) {
        this.$swal.fire({
          titleText: `Are you sure to delete ${item.keyName}?`,
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.value) {
            this.delete_agree(item)
            // this.delete_dialog = true
          }
        })
      },

      async delete_agree (item) {
        // console.log('delete_agree')
        this.loading = true
        const index = this.fido2Keys.indexOf(item)
        var url = `${this.urls.child.delete}/${this.username}/${item.keyID}`
        // if (this.isMaster) url = `${this.urls.master.delete}/${item.keyID}`
        await axios.delete(url)
          .then(response => {
            if (response.data.success) {
              this.fido2Keys.splice(index, 1)

              this.$swal.fire({
                titleText: `Key ${item.keyName} has been deleted`,
                icon: 'success',
                showCancelButton: false,
              })
            // this.show_message('success', 'Delete success!')
            }
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false)) // set loading to false when request finish
      },

      /* _____________________Helper stuff_____________________ */
      /* ______________________________________________________ */
      close () {
        // console.log('close')
        this.editDialog = false
        this.regisDialog = false
        this.$nextTick(() => {
          this.editedFido2Key = Object.assign({}, this.defaultFido2Key)
          this.editedIndex = -1
          this.editedId = -1
        })
      },

      countDownTimer () {
        // console.log('countDownTimer')
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
      },

      rndStr (len) {
        // console.log('rndStr')
        let text = ''
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }

        const rightNow = new Date()
        text = rightNow.toISOString().slice(0, 10).replace(/-/g, '') + '_' + text
        return text
      },

      getColor (status) {
        if (status === true) {
          return 'green'
        } else { return 'gray' }
      },

      getStatus (status) {
        if (status === false) {
          return 'InActive'
        } else if (status === true) {
          return 'Active'
        } else { return 'Undefined' }
      },

      handleErrors (e, api = '') {
        if (e.response === undefined) {
          this.show_message('error', e.toString())
        } else if (!e.response.data.success) {
          if (e.response.data.errorMessage) {
            this.show_message('error', e.response.data.errorMessage)
          } else if (e.response.data.validations) {
            this.show_message('error', e.response.data.validations[0].message)
          } else {
            this.show_message('error', e.response.data.message)
          }
        } else {
          this.show_message('error', `${api} api service not available!`)
        }
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          titleText: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>

<style lang="sass">
#fido2Keys
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>th
    font-size: .85rem !important
</style>
